<template>
  <div>
    <div class="body">
      <div class="baseinfo-title">基本信息</div>
      <div class="baseinfo-card">
        <div class="baseinfo-item">
          <span class="baseinfo-item-label">就诊人：</span>{{ name }}
        </div>
        <div class="baseinfo-item">
          <span class="baseinfo-item-label">就诊卡号：</span>{{ patId }}
        </div>
        <div class="baseinfo-item">
          <span class="baseinfo-item-label">执行科室：</span
          >{{ result.dept_name }}
        </div>
        <div class="baseinfo-item">
          <span class="baseinfo-item-label">检验点：</span
          >河南省第二人民医院本部
        </div>
        <div class="van-hairline--bottom"></div>
      </div>
      <div class="baseinfo-title">基本信息</div>
      <div class="baseinfo-payInfo">
        <van-cell-group :border="false">
          <van-cell center v-for="(item, index) in result.list" :key="index">
            <template #title>
              <span>检查项目</span>
            </template>
            <template #default>
              <span>￥{{ parseInt(item.amount).toFixed(2) }}*1</span>
            </template>
            <template #label>
              <span>{{ item.item_name }}</span>
            </template>
          </van-cell>
          <van-cell title="">
            <template #default>
              <span :style="{ fontSize: '0.42rem', color: '#E27A66' }"
                >付款金额：￥{{ parseInt(result.total).toFixed(2) }}</span
              >
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="info-notice">注意：如需医保支付，请前往医院现场开单</div>
    <van-submit-bar :price="parseInt(result.total * 100)">
      <template #button>
        <van-button
          type="primary"
          color="#4AC691"
          @click="onSubmit"
          class="button-confirm"
          >确认</van-button
        >
      </template>
    </van-submit-bar>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "infoConfirm",
  data() {
    return {
      name: "",
      patId: "",
      healthId: "",
      testPrice: 1,
      price: 1,
      result: {
        total: "0",
      },
      // testPrice: parseInt(this.$route.query.personType) == 0 ? 6000 : 4000,
      // price: parseInt(this.$route.query.personType) == 0 ? 6000 : 4000,
    };
  },
  methods: {
    onSubmit() {
      this.$router.replace({
        path: "/wxPayPage",
        query: {
          id: this.result.order_id,
          price: this.result.total * 100,
          collectType: this.$route.query.collectType,
          type: 0,
          itemName: this.result.list[0].item_name,
          success: false, // 是否跳转到支付成功的页面
        },
      });
    },
    init() {
      this.$toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      let postData = {};
      postData.userid = sessionStorage.getItem("openid");
      postData.name = this.$route.query.name;
      postData.idNumber = this.$route.query.idNumber;
      postData.phone = this.$route.query.phone;
      postData.addressType = this.$route.query.addressType;
      postData.personType = this.$route.query.personType;
      postData.healthId = this.healthId;
      postData.collectType = this.$route.query.collectType;
      if (this.$route.query.personType == 1) {
        postData.inpDept = this.$route.query.inpDept;
        postData.inpNo = this.$route.query.inpNo;
      }
      this.$http
        .post("/api/nucleic", postData)
        .then((result) => {
          if (result.data) {
            this.result = result.data;
            this.$toast.clear();
          }
        })
        .catch((err) => {
          Toast.fail({
            message: err.response.data,
            duration: 500,
            onClose: () => {
              this.$router.back();
            },
          });
        });
    },
  },
  mounted() {
    const CurrentJZPerson = JSON.parse(
      sessionStorage.getItem("CurrentJZPerson")
    );
    this.name = CurrentJZPerson.name;
    this.patId = CurrentJZPerson.patId;
    this.healthId = CurrentJZPerson.healthId;
    this.init();
  },
};
</script>

<style scoped>
.body {
  background-color: #fff;
}

.baseinfo-title {
  font-size: 0.4rem;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
}

.baseinfo-title:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 15px;
  margin: 0px 5px;
  background-color: #00d48a;
  position: relative;
  top: 3px;
  border-radius: 5px;
}

.baseinfo-card {
  text-align: left;
  padding: 12px;
}

.baseinfo-item {
  font-size: 0.4rem;
  line-height: 24px;
}

.baseinfo-item-label {
  width: 80px;
  display: inline-block;
  text-align: left;
}

.baseinfo-payInfo {
  text-align: left;
}

.van-hairline--bottom {
  height: 15px;
}
.info-notice {
  margin-top: 0.5rem;
  font-size: 0.4rem;
  color: #1d9ecf;
}
.button-confirm {
  width: 4rem;
  height: 100%;
  font-size: 0.45rem;
}
/deep/ .van-submit-bar__bar {
  padding: 0;
}
</style>
